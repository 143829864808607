import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/financeiro",
    name: "p-financial",
    redirect: { name: "p-financial-overview" },
    component: () => import("@/views/financial/index.vue"),
    children: [
      {
        path: "/dashboard/financeiro/overview",
        name: "p-financial-overview",
        redirect: { name: "p-financial-payment-list" },
        component: () => import("@/views/financial/overview.vue"),
        children: [
          {
            path: "/dashboard/financeiro/pagamentos",
            name: "p-financial-payment-list",
            component: () => import("@/views/financial/payment/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/pagamentos/cadastrar",
                name: "p-financial-payment-create",
                component: () => import("@/views/financial/payment/form.vue"),
              },
              {
                path: "/dashboard/financeiro/pagamentos/:id/editar",
                name: "p-financial-payment-edit",
                component: () => import("@/views/financial/payment/form.vue"),
              },
            ],
          },
          {
            path: "/dashboard/financeiro/recebimentos",
            name: "p-financial-receipt-list",
            component: () => import("@/views/financial/receipt/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/recebimentos/cadastrar",
                name: "p-financial-receipt-create",
                component: () => import("@/views/financial/receipt/form.vue"),
              },
              {
                path: "/dashboard/financeiro/recebimentos/:id/editar",
                name: "p-financial-receipt-edit",
                component: () => import("@/views/financial/receipt/form.vue"),
              },
            ],
          },
          {
            path: "/dashboard/financeiro/transferencias",
            name: "p-financial-transfer-list",
            component: () => import("@/views/financial/transfer/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/transferencias/cadastrar",
                name: "p-financial-transfer-create",
                component: () => import("@/views/financial/transfer/form.vue"),
              },
              {
                path: "/dashboard/financeiro/transferencias/:id/editar",
                name: "p-financial-transfer-edit",
                component: () => import("@/views/financial/transfer/form.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/empresas",
        name: "p-financial-company-list",
        component: () => import("@/views/financial/company/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/empresas/cadastrar",
            name: "p-financial-company-create",
            component: () => import("@/views/financial/company/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/empresas/:id/editar",
            name: "p-financial-company-edit",
            component: () => import("@/views/financial/company/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/parceiros",
        name: "p-financial-partner-list",
        component: () => import("@/views/financial/partner/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/parceiros/cadastrar",
            name: "p-financial-partner-create",
            component: () => import("@/views/financial/partner/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/parceiros/:id/editar",
            name: "p-financial-partner-edit",
            component: () => import("@/views/financial/partner/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/contas-bancarias",
        name: "p-financial-bank-list",
        component: () => import("@/views/financial/bank/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/contas-bancarias/cadastrar",
            name: "p-financial-bank-create",
            component: () => import("@/views/financial/bank/form.vue"),
          },
          {
            path: "/dashboard/financeiro/contas-bancarias/:id/editar",
            name: "p-financial-bank-edit",
            component: () => import("@/views/financial/bank/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/departamentos",
        name: "p-financial-sector-list",
        component: () => import("@/views/financial/sector/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/departamentos/cadastrar",
            name: "p-financial-sector-create",
            component: () => import("@/views/financial/sector/form.vue"),
          },
          {
            path: "/dashboard/financeiro/departamentos/:id/editar",
            name: "p-financial-sector-edit",
            component: () => import("@/views/financial/sector/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/categorias",
        name: "p-financial-category-list",
        component: () => import("@/views/financial/category/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/categorias/cadastrar",
            name: "p-financial-category-create",
            component: () => import("@/views/financial/category/form.vue"),
          },
          {
            path: "/dashboard/financeiro/categorias/:id/editar",
            name: "p-financial-category-edit",
            component: () => import("@/views/financial/category/form.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
